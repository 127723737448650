// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap";
import "./slick-sliders";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
AOS.init();

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
  collapse: false,
});
/**
* Easy selector helper function
*/
const select = (el, all = false) => {
  el = el.trim()
  if (all) {
    return [...document.querySelectorAll(el)]
  } else {
    return document.querySelector(el)
  }
}

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
  let selectEl = select(el, all)
  if (selectEl) {
    if (all) {
      selectEl.forEach(e => e.addEventListener(type, listener))
    } else {
      selectEl.addEventListener(type, listener)
    }
  }
}

/**
 * Easy on scroll event listener
 */
const onscroll = (el, listener) => {
  el.addEventListener('scroll', listener)
}

/**
 * Navbar links active state on scroll
 */
let navbarlinks = select('#navbar .scrollto', true)
const navbarlinksActive = () => {
  let position = window.scrollY + 200
  navbarlinks.forEach(navbarlink => {
    if (!navbarlink.hash) return
    let section = select(navbarlink.hash)
    if (!section) return
    if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
      navbarlink.classList.add('active')
    } else {
      navbarlink.classList.remove('active')
    }
  })
}
window.addEventListener('load', navbarlinksActive)
onscroll(document, navbarlinksActive)

/**
 * Scrolls to an element with header offset
 */
const scrollto = (el) => {
  let header = select('#header')
  let offset = header.offsetHeight

  if (!header.classList.contains('header-scrolled')) {
    offset -= 16
  }

  let elementPos = select(el).offsetTop
  window.scrollTo({
    top: elementPos - offset,
    behavior: 'smooth'
  })
}

/**
 * Header fixed top on scroll
 */
let selectHeader = select('#header')
if (selectHeader) {
  let headerOffset = selectHeader.offsetTop
  let nextElement = selectHeader.nextElementSibling
  const headerFixed = () => {
    if ((headerOffset - window.scrollY) <= 0) {
      selectHeader.classList.add('fixed-top')
      nextElement.classList.add('scrolled-offset')
      document.getElementById('unscrolled-logo').classList.add('d-none');
      document.getElementById('scrolled-logo').classList.remove('d-none');
    } else {
      selectHeader.classList.remove('fixed-top')
      nextElement.classList.remove('scrolled-offset')
      document.getElementById('unscrolled-logo').classList.remove('d-none');
      document.getElementById('scrolled-logo').classList.add('d-none');
    }
  }
  window.addEventListener('load', headerFixed)
  onscroll(document, headerFixed)
}


/**
 * Mobile nav toggle
 */
on('click', '.mobile-menu-toggle', function (e) {
  $('.mobile-menu').fadeIn();
})

/**
 * Mobile nav dropdowns activate
 */
on('click', '.navbar .dropdown > a', function (e) {
  if (select('#navbar').classList.contains('navbar-mobile')) {
    e.preventDefault()
    this.nextElementSibling.classList.toggle('dropdown-active')
  }
}, true)

/*FAQ's*/
const items = document.querySelectorAll(".accordion button");

function toggleAccordion() {
  const itemToggle = this.getAttribute('aria-expanded');
  var i = 0;
  for (i = 0; i < items.length; i++) {
    items[i].setAttribute('aria-expanded', 'false');
  }

  if (itemToggle == 'false') {
    this.setAttribute('aria-expanded', 'true');
  }
}

items.forEach(item => item.addEventListener('click', toggleAccordion));

//Custom Select
var x, i, j, l, ll, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function (e) {
      /*when an item is clicked, update the original select box,
      and the selected item:*/
      var y, i, k, s, h, sl, yl;
      s = this.parentNode.parentNode.getElementsByTagName("select")[0];
      sl = s.length;
      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName("same-as-selected");
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute("class");
          }
          this.setAttribute("class", "same-as-selected");
          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function (e) {
    /*when the select box is clicked, close any other select boxes,
    and open/close the current select box:*/
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle("select-hide");
    this.classList.toggle("select-arrow-active");
  });
}
function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

// setInterval(function () {
//   $('.logos-second').delay(5000).fadeIn('slow', function () {
//     $('.logos-first').delay(5000).fadeOut();
//   });
// }, 20000);

// setInterval();

$('.btn-have-less-workplace-injuries').click(function (event) {
  event.preventDefault();

  $('.modal-form').fadeIn();
  $('#demo').fadeOut();

  $('body').addClass("modal-open");
});

$('.close-modal').click(function () {
  $('.modal-form').fadeOut();
  $('#demo').fadeIn();
  $('body').removeClass("modal-open");
});

$('.show-features').click(function () {
  $('#productFeatures').removeClass("d-none");
  $('.show-features').removeClass("d-block");
  $(this).addClass("d-none");
  $('.hide-features').removeClass("d-none");
  $('.hide-features').addClass("d-block");
  console.log('clicked to show features');

});

$('.hide-features').click(function () {
  $('#productFeatures').addClass("d-none");
  $(this).addClass("d-none");
  $(this).removeClass("d-block");
  $('.show-features').removeClass("d-none");
  $('.show-features').addClass("d-block");
});

$('.show-benefits').click(function () {
  $('#productbenefits').removeClass("d-none");
  $('.show-benefits').removeClass("d-block");
  $(this).addClass("d-none");
  $('.hide-benefits').removeClass("d-none");
  $('.hide-benefits').addClass("d-block");
  console.log('clicked to show benefits');

});

$('.hide-benefits').click(function () {
  $('#productbenefits').addClass("d-none");
  $(this).addClass("d-none");
  $(this).removeClass("d-block");
  $('.show-benefits').removeClass("d-none");
  $('.show-benefits').addClass("d-block");
});

jQuery(document).ready(function () {
  var url = window.location.pathname;
  jQuery('.hidden-url').attr("value", url);
});


if ($(".hero-banner")[0]){
  $('header').addClass('bg-transparent');
} else {
  $('header').addClass('bg-transparent');
}

$("#range").ionRangeSlider({
  hide_min_max: true,
  keyboard: true,
  min: 0,
  max: 5000,
  from: 1000,
  to: 4000,
  type: 'double',
  step: 1,
  prefix: "$",
  grid: true
});


$('#slide-contact-strip').click(function () {
  $(this).addClass("d-none");
  $('#slide-contact-strip-up').removeClass("d-none");
  $('.contact-strip-reveal').removeClass("d-none");
});

$('#slide-contact-strip-up').click(function () {
  $('.contact-strip-reveal').addClass("d-none");
  $(this).addClass("d-none");
  $('#slide-contact-strip').removeClass("d-none");
});

