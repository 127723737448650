(function ($) {
    class SlickCarousels {
        constructor() {
            this.testimonialCarousel();
        }
        testimonialCarousel() {
            $('.testimonials-slider').slick({
                arrows: false,
                slidesToShow: 3,
                autoplay: true,
                autoplaySpeed: 4500,
                infinite: true,
                pauseOnFocus: false,
                pauseOnHover: false,
                slidesToScroll: 1,
            });
            $('.previous-testimonial').click(function () {
                $('.testimonials-slider').slick('slickPrev');
            });
            $('.next-testimonial').click(function () {
                $('.testimonials-slider').slick('slickNext');
            });

        }
        
    }
    new SlickCarousels();

})(jQuery);